import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { useNavigate } from 'react-router';
import { UIIconButton, UIParam } from 'finbox-ui-kit';
import { decl, formatInitials } from 'finbox-ui-kit/utils';
import { Timer } from '@/common/time-tracker/timer';
import { formatPhoneNumber } from '@/utils';
import { Nullable } from '@/types/_helpers';
import { ILeadItem } from '@/types/lead';
import { Phone } from '@/common';

import { useClientTasksContext } from '@/context/client-tasks.context';
import { useClientContext } from '@/context/client.context';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { ClientSource, SOCKET_EVENT } from '@/consts';
import { Button, Icon, Label } from '@/common/ui';
import styles from './incoming-call.module.scss';

const audio = new Audio('/assets/sounds/incoming_call_notify.mp3');

type TEventData = {
    number: string,
    clients: Nullable<ILeadItem[]>,
    callId: number,
}
const IncomingCall = () => {
    const navigate = useNavigate();
    const { subscribe, unsubscribe } = useSocketsContext();
    const [ showed, setShowed ] = useState(false);
    const [ minimized, setMinimized ] = useState(false);

    const [ data, setData ] = useState<TEventData>(null);
    // const [ data, setData ] = useState<TEventData>({
    //     number: '79182992151',
    //     clients: [
    //         {
    //             name: 'Александр',
    //             surname: 'Киселёв',
    //         },
    //     ],
    // } as any);
    const [ isAnswered, setIsAnswered ] = useState(false);
    const [ isFinished, setIsFinished ] = useState(false);
    const [ isShowedAllClients, setIsShowedAllClients ] = useState(false);

    const { addTask } = useClientTasksContext();
    const { createClient } = useClientContext();

    const handlerEventIncomingCall = useCallback(async (data: TEventData) => {
        setData(data);
        setShowed(true);
        setMinimized(false);
        setIsAnswered(false);
        setIsFinished(false);
        setIsShowedAllClients(false);
        await audio.play();
    }, []);

    const handlerEventAnsweredCall = useCallback(() => {
        setIsAnswered(true);
    }, []);

    const handlerEventFinishedCall = useCallback(() => {
        if (isAnswered) {
            setTimeout(() => {
                setShowed(false);
            }, 3000);
        } else {
            setMinimized(true);
        }
        setIsFinished(true);
    }, [ isAnswered ]);

    useEffect(() => {
        subscribe(SOCKET_EVENT.INCOMING_CALL, handlerEventIncomingCall);
        subscribe(SOCKET_EVENT.ANSWERED_CALL, handlerEventAnsweredCall);
        subscribe(SOCKET_EVENT.END_CALL, handlerEventFinishedCall);
        return () => {
            unsubscribe(SOCKET_EVENT.INCOMING_CALL, handlerEventIncomingCall);
            unsubscribe(SOCKET_EVENT.ANSWERED_CALL, handlerEventAnsweredCall);
            unsubscribe(SOCKET_EVENT.END_CALL, handlerEventFinishedCall);
        };
    }, [ handlerEventAnsweredCall, handlerEventFinishedCall, handlerEventIncomingCall, subscribe, unsubscribe ]);

    const handlerClickMinimize = useCallback(() => {
        setMinimized(true);
    }, []);

    const handlerClickMaximize = useCallback(() => {
        setMinimized(false);
    }, []);

    const handlerClickClose = useCallback(() => {
        setShowed(false);
    }, []);

    const handlerClickShowAllClients = useCallback(() => {
        setIsShowedAllClients(true);
    }, []);

    const handlerCLickClient = useCallback((client: ILeadItem) => () => {
        if (client.deleted) {
            navigate(`/garbage/?id=${ client.id }`);
        } else if (client.contracted) {
            navigate(`/contracts/?leadId=${ client.id }`);
        } else {
            navigate(`/leads/${ client.id }/`);
        }
        setMinimized(true);
    }, [ navigate ]);

    const handlerCLickCreate = useCallback(() => {
        createClient({
            source: ClientSource.CALL,
            phone: data.number,
            cid: data.callId,
        });
        setMinimized(true);
    }, [ createClient, data?.callId, data?.number ]);

    const formattedNumber = formatPhoneNumber(data?.number);

    const showedClient = data?.clients?.find(((i) => !i.deleted));
    const otherClients = data?.clients?.filter((i) => i.id !== showedClient?.id);

    const handlerClickAddTask = useCallback(() => {
        addTask(showedClient.id, () => {
            setMinimized(false);
        });
        setMinimized(true);
    }, [ addTask, showedClient?.id ]);

    return (
        <div className={ cn(styles.incomingCall, {
            [styles.incomingCallShowed]: showed,
            [styles.incomingCallMinimized]: minimized,
        }) }>
            <div className={ styles.incomingCallHeader }>
                <Icon name='phone-volume' type='solid'/>
                <div className={ styles.incomingCallHeaderText }>
                    <div className={ styles.incomingCallHeaderTextTitle }>Входящий звонок</div>
                    <div className={ styles.incomingCallHeaderTextPhone }>
                        { data?.clients ? formatInitials(showedClient, true) : formattedNumber }
                    </div>
                </div>
                <div className={ styles.incomingCallHeaderTimer }>
                    { isAnswered && !isFinished && (
                        <Timer isActive={ !isFinished }/>
                    ) }
                    { !isAnswered && isFinished && (
                        <Label color='red'>Пропущен</Label>
                    ) }
                    { isAnswered && isFinished && (
                        <Label color='green'>Завершён</Label>
                    ) }
                </div>
                { !minimized && (
                    <UIIconButton icon='down-to-line' onClick={ handlerClickMinimize } iconType='regular' color='white'/>
                ) }
                { minimized && (
                    <UIIconButton icon='window-maximize' onClick={ handlerClickMaximize } iconType='regular' color='white'/>
                ) }
                <UIIconButton icon='xmark' onClick={ handlerClickClose } color='white'/>
            </div>
            <div className={ styles.incomingCallBody }>
                <div className={ styles.incomingCallBodyWrapper }>
                    <UIParam label='Номер'>
                        <Phone number={ data?.number }/>
                    </UIParam>
                    <UIParam label='Клиент'>
                        { showedClient ? (
                            <Button className='link-button color-blue fz14' onClick={ handlerCLickClient(showedClient) }>
                                { data.clients.length > 1 && '1. ' }
                                { formatInitials(showedClient, true) }{ ' ' }
                                ({ formatInitials(showedClient.currentManager, true) })
                                { ' - ' }
                                { showedClient.deleted && 'Удалён' }
                                { showedClient.contracted && 'Сделка' }
                                { !showedClient.deleted && !showedClient.contracted && 'Активен' }
                            </Button>
                        ) : (<span className='color-grayDark fz12'>Не найден</span>) }
                        { data?.clients?.length > 1 && !isShowedAllClients && (
                            <div>
                                <Button
                                    className='link-button'
                                    onClick={ handlerClickShowAllClients }
                                >
                                    и еще { otherClients.length - 1 } { decl(otherClients.length - 1, [ 'клиент', 'клиента', 'клиентов' ]) }
                                </Button>
                            </div>
                        ) }
                        { data?.clients?.length > 1 && isShowedAllClients && otherClients.map((client, index) => (
                            <div key={ client.id }>
                                <Button
                                    className='link-button color-blue'
                                    onClick={ handlerCLickClient(client) }
                                >
                                    { index + 2 }.&nbsp;
                                    { formatInitials(client, true) }{ ' ' }
                                    ({ formatInitials(client.currentManager, true) })
                                    { ' - ' }
                                    { client.deleted && 'Удалён' }
                                    { client.contracted && 'Сделка' }
                                    { !client.deleted && !client.contracted && 'Активен' }
                                </Button>
                            </div>
                        )) }
                    </UIParam>
                    <Button.Group>
                        { !data?.clients?.some((i) => !i.deleted) && (
                            <Button
                                icon='user-plus'
                                color='teal'
                                onClick={ handlerCLickCreate }
                            >
                                Создать клиента
                            </Button>
                        ) }
                        { data?.clients?.some((i) => !i.deleted) && (
                            <Button
                                icon='user'
                                onClick={ handlerCLickClient(data.clients[0]) }
                                color='teal'
                            >
                                Открыть клиента
                            </Button>
                        ) }
                        { showedClient && (
                            <Button
                                icon='calendar-plus'
                                onClick={ handlerClickAddTask }
                            >
                                Добавить задачу
                            </Button>
                        ) }
                    </Button.Group>
                </div>
            </div>
        </div>
    );
};

export default IncomingCall;
