/* eslint-disable no-console */
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { socket } from '@/libs/socket';
import { getJwtToken, memoizedRefreshToken } from '@/libs/jwt-token';
import { Button, Icon, Modal } from '@/common/ui';

const debug = false;

type TSocketsContext = {
    connect(userId: number): void;
    disconnect(): void;
    subscribe(event: string, callback: (data: any) => void): void;
    unsubscribe(event: string, callback: (data: any) => void): void;
};

function socketAuthCallback(uid: number) {
    return (cb) => {
        const token = getJwtToken();
        if (!token) {
            alert('Ошибка подключения к серверу. Пожалуйста, перезагрузите страницу.');
        }
        cb({
            uid,
            token: token,
        });
    };
}

const SocketsContext = React.createContext<TSocketsContext>({} as TSocketsContext);

export function useSocketsContext() {
    return React.useContext(SocketsContext);
}

let retries = 0;
let buffer: any;
type TSocketsProps = {
    children?: ReactNode
};
export const SocketsProvider = ({ children }: TSocketsProps) => {
    const ref = useRef({
        userId: null,
    });
    const [ connectionLost, setConnectionLost ] = useState(false);
    const [ isConnectionInit, setIsConnection ] = useState(false);


    const connect = useCallback((userId: number) => {
        if (debug) { console.log('[Sockets] connect ', userId); }
        ref.current.userId = userId;
        socket.connect();
        setIsConnection(true);
    }, []);

    useEffect(() => {
        if (debug) { console.log('[Sockets] INIT SOCKETS'); }
        socket.auth = async (cb) => {
            cb({
                uid: ref.current.userId,
                token: await memoizedRefreshToken(),
            });
            retries = 0;
            socket.auth = socketAuthCallback(ref.current.userId);
        };
        // socket.auth = socketAuthCallback;

        socket.on('token_expired', () => {
            if (retries > 10) {
                setConnectionLost(true);
            } else {
                retries++;
                setTimeout(() => {
                    console.log('[Sockets] reconnect on token_expired');
                    socket.auth = async (cb) => {
                        cb({ token: await memoizedRefreshToken() });
                        socket.auth = socketAuthCallback(ref.current.userId);
                    };
                    connect(ref.current.userId);
                }, 1000);
            }
        });

        socket.on('connect', () => {
            if (debug) { console.log('[Sockets] connected'); }
            clearTimeout(buffer);
            buffer = setTimeout(() => {
                setConnectionLost(false);
                retries = 0;
            }, 2000);
        });

        socket.on('connect_error', (err) => {
            console.log('[Sockets] connect_error');
            console.log(err);
        });

        socket.on('disconnect', (err) => {
            console.log('[Sockets] disconnected');
            console.error(err);
            setConnectionLost(true);
        });

        return () => {
            socket.disconnect();
        };
    }, [ connect ]);


    const disconnect = useCallback(() => {
        socket.disconnect();
        setIsConnection(false);
    }, []);

    const subscribe = useCallback((event: string, callback: (data: any) => void) => {
        if (debug) { console.log('[Sockets] subscribe', event); }
        socket.on(event, callback);
    }, []);

    const unsubscribe = useCallback((event: string, callback: (data: any) => void) => {
        if (debug) { console.log('[Sockets] unsubscribe', event); }
        socket.off(event, callback);
    }, []);

    return (
        <SocketsContext.Provider value={ {
            connect,
            disconnect,
            subscribe,
            unsubscribe,
        } }>
            { children }
            <Modal open={ connectionLost && isConnectionInit } size='tiny' closeOnDimmerClick={ false }>
                <Modal.Content className='text-center pb2'>
                    <h3 className='mb2 mt1'>Соединение с сервером</h3>
                    <div className='fz36 mt1 mb1'>
                        <Icon name='signal-slash' type='thin' color='red'/>
                    </div>
                    Идёт повторное подключение...
                    <Button
                        className='mt1'
                        content='Перезагрузить страницу'
                        onClick={ () => window.location.reload() }
                    />
                </Modal.Content>
            </Modal>
        </SocketsContext.Provider>
    );
};
