import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TCounters, TFUITopMenuItem } from 'finbox-ui-kit/components/top-menu/desktop/top-menu';
import { UITopMenuMobile } from 'finbox-ui-kit/components/top-menu/mobile';
import { useAuthContext } from '@/context/auth.context';
import { API_COUNTERS_GLOBAL } from '@/components/top-menu/_consts';
import { UserRole } from '@/consts';
import { buildTopMenu } from '@/components/top-menu/top-menu-stucture';
import NotificationsIcon from '@/common/user-notifications';
import { SearchGlobal } from '@/components/top-menu/components/search';
import { TimeTracker } from '@/common/time-tracker';
import { TUser } from '@/types/manager';
import { axiosPrivate } from '@/libs/api-client/axiosPrivate';
import { LeftMenuUser } from '@/common/left-menu-user';


interface IMenuProps {
    isAdmin: boolean;
    user: TUser;
}


export function LeftMenu({ user }: IMenuProps) {
    const { checkRoles } = useAuthContext();
    const { data: counters } = useQuery<TCounters>({
        queryKey: [ API_COUNTERS_GLOBAL ],
        queryFn: async () => {
            const { data } = await axiosPrivate(API_COUNTERS_GLOBAL);
            return data;
        },
        enabled: checkRoles(UserRole.admin, UserRole.manager, UserRole.creditors_manager),
    });

    const menuItems = useMemo<TFUITopMenuItem[]>(() => [
        ...buildTopMenu(user),
        ...checkRoles(UserRole.manager) ? [
            {
                right: true,
                render: (
                    <div style={ { height: 49, display: 'flex', alignItems: 'stretch' } }>
                        <TimeTracker/>
                    </div>
                ),
            },
        ] : [],
    ], [ checkRoles, user ]);

    return (
        <div
            style={ {
                position: 'fixed',
                left: 0,
                width: 230,
                height: '100vh',
                backgroundColor: '#2c3e50',
                color: '#fff',
                zIndex: 1000,
            } }
        >
            <UITopMenuMobile
                logoText={ (
                    <img src='/assets/images/logo.png' style={ {
                        width: 24,
                    } } alt='Фонд Финансирования'/>
                ) }
                logoBackgroundColor='#bf0000'
                logoTextColor='#fff'
                logoLink='/'
                items={ menuItems }
                counters={ counters }
                icons={ [
                    ...checkRoles(UserRole.admin, UserRole.manager, UserRole.creditors_manager) ? [
                        {
                            render: (
                                <NotificationsIcon
                                    position='right top'
                                />
                            ),
                        },
                    ] : [],
                    {
                        render: (
                            <LeftMenuUser user={ user }></LeftMenuUser>
                        ),
                    },
                ] }
                search={ (
                    <SearchGlobal key='global_search' modal/>
                ) }
                desktop
            />
        </div>
    );
}