import { useRef, useState } from 'react';

import './notifications.style.scss';
import cn from 'classnames';
import { useAtomValue } from 'jotai/index';
import { NotificationCountState } from '@/context/notifications.context';
import { Icon, Label, Popup, Tooltip } from '@/common/ui';
import { TPopupPosition } from '@/common/ui/popup/popup';
import { decl, formatMoney } from '@/utils';
import { NotificationsList } from './notifications-list';


export const NotificationsIcon = ({ position = 'bottom right' }: {
    position?: TPopupPosition
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [ open, setOpen ] = useState(false);
    const count = useAtomValue(NotificationCountState);

    const handlerClickIcon = () => {
        setOpen(true);
    };

    const handlerClose = () => {
        setOpen(false);
    };

    return (
        <div ref={ ref } className={ cn('notifications', { '-open': open, '-animated': count > 0 }) }>
            <Tooltip
                content={ `${ count } ${ decl(count, [ 'уведомление', 'уведомления', 'уведомлений' ]) }` }
                trigger={ (ref) => (
                    <div ref={ ref } className='notifications-icon' onClick={ handlerClickIcon }>
                        <Icon className='notifications-icon-icon' name='bell' size='big' color='white' type={ count > 0 ? 'solid' : 'regular' }/>
                        { count > 0 && (
                            <Label className='ml-5' color='red'>
                                { formatMoney(count, false) }
                            </Label>
                        ) }
                    </div>
                ) }
            />
            <Popup.Wrapper>
                <Popup
                    targetRef={ ref }
                    open={ open }
                    onClickOutside={ handlerClose }
                    position={ position }
                    className='notificationsPopup'
                    strategy='fixed'
                >
                    <NotificationsList
                        onClose={ handlerClose }
                    />
                </Popup>
            </Popup.Wrapper>
        </div>
    );
};
